import React from 'react'
import Users from "./Users";
import Search from './Search'
function Home() {
    return (
        <div>
            {/* Search component */}
            <Search/>
            <Users/>
            
            
        </div>
    )
}

export default Home
